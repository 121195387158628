import { ChevronLeftIcon } from "@heroicons/react/20/solid";
import {
  IonAvatar,
  IonChip,
  IonContent,
  IonImg,
  IonLabel,
  IonPage,
  IonText,
} from "@ionic/react";
import { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import Avatar from "../../components/Avatar";
import ScrollableContent from "../../components/ScrollableContent";
import Letter from "../../data/Letter";
import { Match } from "../../data/Match";
import { InMemoryCache } from "../../hooks/CacheHook";
import { JIBI_BASE_URL } from "../../util/ApiClient";
import { UserContext } from "../../util/BetterDatesApp";
import { formatDateToHumanReadable } from "../../util/date-ext";

export default function ReadLetter() {
  const params = useParams<ReadLetterProps>();
  const history = useHistory();
  const userContext = useContext(UserContext);
  if (!userContext?.userState.loggedIn) {
    return <></>;
  }
  const [state, setState] = useState<ReadLetterStateType>({ loading: true });
  const savedUser = userContext.userState.savedUser;

  useEffect(() => {
    const cachedMatch = getCachedMatch(userContext.cache, params.matchId);
    if (!cachedMatch) {
      history.replace("");
      return;
    }
    const cachedLetter = getCachedLetter(
      userContext.cache,
      params.matchId,
      params.letterId,
    );
    if (!cachedLetter) {
      history.replace("");
      return;
    }
    setState({ loading: false, match: cachedMatch, letter: cachedLetter });
  }, []);

  return (
    <IonPage className="flex items-center justify-center bg-gray-50">
      <div className="h-full w-full max-w-md">
        <div className="safe-scroller flex h-full w-full flex-col justify-between">
          <header className="flex h-14 flex-row items-center justify-between px-3">
            <IonChip
              onClick={(e) => {
                e.preventDefault();
                history.goBack();
              }}
            >
              <ChevronLeftIcon className="me-1 h-5 w-5 text-gray-500" />
              <IonLabel>Back</IonLabel>
            </IonChip>
          </header>
          <main className="flex h-full w-full flex-col">
            {state.loading ? (
              <div className="flex h-full w-full items-center justify-center">
                <IonText>Loading...</IonText>
              </div>
            ) : (
              <div className="flex h-full w-full flex-col">
                <div className="flex flex-col px-8">
                  <div className="flex flex-row items-center gap-4 py-5">
                    <div className="flex flex-col items-center">
                      <IonAvatar className="h-12 w-12">
                        <Avatar
                          src={`${JIBI_BASE_URL}/files/_pb_users_auth_/${
                            state.letter.from === savedUser.user.id
                              ? savedUser.user.id
                              : state.match.partyTwo.id
                          }/${
                            state.letter.from === savedUser.user.id
                              ? savedUser.user.avatar
                              : state.match.partyTwo.avatar
                          }`}
                          alt={
                            state.letter.from === savedUser.user.id
                              ? `${savedUser.user.name}'s avatar`
                              : `${state.match.partyTwo.name}'s avatar`
                          }
                          rounded={true}
                        />
                      </IonAvatar>
                    </div>
                    <div className="flex w-full flex-row items-center justify-center">
                      <IonImg
                        src="/assets/arrow_right.svg"
                        alt=""
                        className="h-8"
                      />
                    </div>
                    <div className="flex flex-col items-center">
                      <IonAvatar className="h-12 w-12">
                        <Avatar
                          src={`${JIBI_BASE_URL}/files/_pb_users_auth_/${
                            state.letter.from === savedUser.user.id
                              ? state.match.partyTwo.id
                              : savedUser.user.id
                          }/${
                            state.letter.from === savedUser.user.id
                              ? state.match.partyTwo.avatar
                              : savedUser.user.avatar
                          }`}
                          alt={
                            state.letter.from === savedUser.user.id
                              ? `${state.match.partyTwo.name}'s avatar`
                              : `${savedUser.user.name}'s avatar`
                          }
                          rounded={true}
                        />
                      </IonAvatar>
                    </div>
                  </div>
                </div>
                <div className="flex-1 overflow-y-hidden px-4">
                  <IonContent className="h-full" scrollY={false}>
                    <ScrollableContent>
                      <div className="mb-2 mt-4 flex w-full flex-col gap-4">
                        <IonText
                          className="whitespace-pre-wrap text-start text-black"
                          dir="auto"
                        >
                          {state.letter.letter}
                        </IonText>
                        <IonText className="text-sm text-gray-500">
                          {formatDateToHumanReadable(
                            new Date(state.letter.sendDate),
                          )}
                        </IonText>
                      </div>
                    </ScrollableContent>
                  </IonContent>
                </div>
              </div>
            )}
          </main>
        </div>
      </div>
    </IonPage>
  );
}

function getCachedMatch(
  cache: InMemoryCache,
  matchId: string,
): Match | undefined {
  const cachedMatches = cache.matches;
  return cachedMatches?.find((element) => element.id === matchId) || undefined;
}

function getCachedLetter(
  cache: InMemoryCache,
  matchId: string,
  letterId: string,
): Letter | undefined {
  const cachedLetters = cache.letters;
  const lettersFromMatch = cachedLetters.get(matchId);
  return (
    lettersFromMatch?.find((element) => element.id === letterId) || undefined
  );
}

interface ReadLetterState {
  loading: boolean;
}

interface ReadLetterStateLoading extends ReadLetterState {
  loading: true;
}

interface ReadLetterStateLoaded extends ReadLetterState {
  loading: false;
  match: Match;
  letter: Letter;
}

type ReadLetterStateType = ReadLetterStateLoading | ReadLetterStateLoaded;

export type ReadLetterProps = {
  matchId: string;
  letterId: string;
};
