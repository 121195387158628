import { calculateAge } from "../util/date-ext";
import User from "../data/User";
import { createValidEmailRegEx } from "../util/regex-ext";

export type ProfileValidator = (user: User) => Validation;

interface Validation {
  valid: boolean;
}

export const isNameValid: ProfileValidator = (user: User) => {
  const valid = (user.name && user.name.length > 0) || false;
  return { valid: valid };
};

export const isAgeValid: ProfileValidator = (user: User) => {
  const valid = (user.birthday && calculateAge(user.birthday) >= 18) || false;
  return { valid: valid };
};

export const isGenderValid: ProfileValidator = (user: User) => {
  return { valid: (user.gender && user.gender.length > 0) || false };
};

export const isSexualOrientationValid: ProfileValidator = (user: User) => {
  return {
    valid:
      (user.sexualOrientation && user.sexualOrientation.length > 0) || false,
  };
};

export const isAvatarValid: ProfileValidator = (user: User) => {
  const valid = (user.avatar && user.avatar.length > 0) || false;
  return { valid: valid };
};

export const isLocationValid: ProfileValidator = (user: User) => {
  const valid = (user.location && user.location.id.length > 0) || false;
  return { valid: valid };
};

export const isEmailValid: ProfileValidator = (user: User) => {
  const emailRegex = createValidEmailRegEx();
  const valid =
    (user.email && user.email.length > 0 && emailRegex.test(user.email)) ||
    false;
  return { valid: valid };
};

export const isMatchPreferencesPresent: ProfileValidator = (user: User) => {
  const valid =
    (user.matchPreference &&
      user.matchPreference.description?.length > 0 &&
      user.matchPreference.gender?.length > 0) ||
    false;
  return { valid: valid };
};

export const isMatchPreferencesGenderPresent: ProfileValidator = (
  user: User,
) => {
  const valid =
    (user.matchPreference && user.matchPreference.gender?.length > 0) || false;
  return { valid: valid };
};

export const isMatchPreferencesDescriptionPresent: ProfileValidator = (
  user: User,
) => {
  const valid =
    (user.matchPreference && user.matchPreference.description?.length > 0) ||
    false;
  return { valid: valid };
};

export const isHeightValid: ProfileValidator = (user: User) => {
  const valid =
    user.height !== undefined && user.height >= 100 && user.height <= 250;
  return { valid: valid };
};

export const isReligionValid: ProfileValidator = (user: User) => {
  const valid = user.religion !== undefined && user.religion.length > 0;
  return { valid: valid };
};

export const profileValidators: ProfileValidator[] = [
  isNameValid,
  isAgeValid,
  isEmailValid,
];

export function isProfileComplete(user: User): boolean {
  for (const validator of profileValidators) {
    const valid = validator(user).valid;
    if (!valid) {
      return false;
    }
  }
  return true;
}
