import { useEffect, useRef, useState } from "react";

export default function ScrollableContent({ children, className = "" }: ScrollableContentProps) {
  const [showTopFade, setShowTopFade] = useState(false);
  const [showBottomFade, setShowBottomFade] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);

  const handleScroll = () => {
    if (contentRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = contentRef.current;
      setShowTopFade(scrollTop > 0);
      setShowBottomFade(scrollTop + clientHeight < scrollHeight);
    }
  };

  useEffect(() => {
    handleScroll();
  }, [children]);

  return (
    <div className="relative flex h-full w-full flex-col">
      {showTopFade && (
        <div className="pointer-events-none absolute left-0 right-0 top-0 z-10 h-12 bg-gradient-to-b from-gray-50 via-gray-50/95 to-transparent" />
      )}
      <div
        ref={contentRef}
        className={`h-full w-full overflow-auto ${className}`}
        onScroll={handleScroll}
      >
        {children}
      </div>
      {showBottomFade && (
        <div className="pointer-events-none absolute bottom-0 left-0 right-0 h-12 bg-gradient-to-t from-gray-50 via-gray-50/95 to-transparent" />
      )}
    </div>
  );
} 

interface ScrollableContentProps {
  children: React.ReactNode;
  className?: string;
}
