import { HttpResponse } from "@capacitor/core";
import {
  ClockIcon,
  ExclamationCircleIcon,
  HeartIcon,
  UserPlusIcon,
} from "@heroicons/react/24/outline";
import { RangeValue } from "@ionic/core";
import { IonButton, IonPage, IonText } from "@ionic/react";
import { useContext, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router";
import Header from "../../components/Header";
import MatchInfo, { ProfilePictureStateType } from "../../components/MatchInfo";
import MatchSlider from "../../components/MatchSlider";
import { Match } from "../../data/Match";
import { MatchesResponse } from "../../data/MatchesResponse";
import { ApplicationStatus } from "../../data/User";
import { SavedUser } from "../../hooks/LoginHook";
import { ERROR_DEFAULT } from "../../i18n/static";
import { GET, JIBI_BASE_URL } from "../../util/ApiClient";
import { UserContext } from "../../util/BetterDatesApp";
import { requestNotificationPermission } from "../../util/NotificationExt";
import {
  isAvatarValid,
  isGenderValid,
  isLocationValid,
  isMatchPreferencesPresent,
  isSexualOrientationValid,
} from "../../validation/ProfileValidation";
import "./Home.css";
import { patchUserAvatar } from "../../datasource/user-datasource";
import { handleError } from "../../util/error";
import { Camera, CameraResultType, CameraSource } from "@capacitor/camera";
import { compressAvatar, getAvatarFile } from "../../util/AvatarExt";

export default function Home() {
  const history = useHistory();
  const userContext = useContext(UserContext);
  if (!userContext?.userState?.loggedIn) {
    return <></>;
  }
  const savedUser = userContext.userState.savedUser;
  const [homeState, setHomeState] = useState<HomeState>({ type: "loading" });
  const [matchIndex, setMatchIndex] = useState<RangeValue>(0);
  const [profilePictureState, setProfilePictureState] =
    useState<ProfilePictureStateType>("innocent");

  const focusedMatch = useMemo(() => {
    return homeState.type === "loaded" && homeState.matches
      ? homeState.matches[matchIndex as number]
      : undefined;
  }, [homeState, matchIndex, userContext.cache.matches]);

  const isMatchPreferencesValid = useMemo(
    () => isMatchPreferencesPresent(savedUser.user).valid,
    [userContext],
  );

  const hasSexualOrientation = useMemo(
    () => isSexualOrientationValid(savedUser.user).valid,
    [userContext],
  );

  const hasGender = useMemo(
    () => isGenderValid(savedUser.user).valid,
    [userContext],
  );

  const hasLocation = useMemo(
    () => isLocationValid(savedUser.user).valid,
    [userContext],
  );

  const hasAvatar = useMemo(
    () => isAvatarValid(savedUser.user).valid,
    [userContext],
  );

  const changeIndex = (index: RangeValue) => {
    setMatchIndex(index);
    setProfilePictureState("innocent");
  };

  const homeStateLoaded = (matches: Match[]) => {
    setMatchIndex(generateInitialMatchIndex(matches.length));
    setHomeState({ type: "loaded", matches: matches });
  };

  const load = () => {
    if (!isMatchPreferencesValid || !hasSexualOrientation || !hasGender) {
      setHomeState({ type: "missingPreferences" });
      return;
    }
    if (!hasAvatar) {
      setHomeState({ type: "missingAvatar" });
      return;
    }
    if (!hasLocation) {
      setHomeState({ type: "missingLocation" });
      return;
    }
    fetchApplicationStatus(savedUser)
      .then((result) => {
        if (result.status !== 200) {
          return Promise.reject(result);
        }
        const response = result.data as ApplicationStatusResponse;
        if (response.items && response.items.length > 0) {
          const status = response.items[0].status as ApplicationStatus;
          const rejectionReasons = response.items[0].expand?.rejectionReasons;
          if (status !== ApplicationStatus.Approved) {
            setHomeState({
              type: "applicationStatus",
              status: status,
              rejectionReasons: rejectionReasons,
            });
            return;
          }
        }

        const cachedMatches = userContext.cache.matches;
        if (cachedMatches) {
          homeStateLoaded(cachedMatches);
        } else if (isMatchPreferencesPresent(savedUser.user).valid) {
          fetchMatches(savedUser.token)
            .then((matchResult) => {
              if (matchResult.status !== 200) {
                return Promise.reject(`status: ${matchResult.status}`);
              }
              const matchResponse = matchResult.data as MatchesResponse;
              if (matchResponse.matches.length > 0) {
                userContext.cacheHook.cacheMatches(matchResponse.matches);
                homeStateLoaded(matchResponse.matches);
              } else {
                setHomeState({ type: "noMatches" });
              }
            })
            .catch((e) => {
              userContext.errorHook.logError("I", e);
              setHomeState({ type: "error", error: ERROR_DEFAULT });
            });
        } else {
          setHomeState({ type: "missingPreferences" });
        }
      })
      .catch((e) => {
        userContext.errorHook.logError("I", e);
        setHomeState({ type: "error", error: ERROR_DEFAULT });
      });
  };

  useEffect(() => {
    load();
  }, [
    isMatchPreferencesValid,
    hasSexualOrientation,
    hasGender,
    hasLocation,
    hasAvatar,
  ]);

  useEffect(() => {
    if (homeState.type === "noMatches" || homeState.type === "loaded") {
      requestNotificationPermission(userContext);
    }
  }, [homeState]);

  return (
    <IonPage className="flex items-center justify-center bg-gray-50">
      <div className="h-full w-full max-w-md">
        <div className="safe-scroller flex h-full w-full flex-col">
          <Header />
          <main className="flex h-full w-full items-center justify-center">
            <div className="flex h-full w-full flex-col px-8">
              {homeState.type === "loaded" && focusedMatch && (
                <div
                  className={`flex h-full w-full flex-col justify-between gap-3 lg:gap-6 xl:gap-8`}
                >
                  <div className="h-full w-full"></div>
                  <div className="h-full w-full">
                    <MatchInfo
                      state={{
                        profilePictureState: {
                          state: profilePictureState,
                          transform: (to) => {
                            setProfilePictureState(to);
                          },
                        },
                      }}
                      name={focusedMatch.partyTwo.name}
                      age={focusedMatch.partyTwo.age}
                      avatar={
                        focusedMatch.partyTwo.avatar
                          ? `${JIBI_BASE_URL}/files/_pb_users_auth_/${focusedMatch.partyTwo.id}/${focusedMatch.partyTwo.avatar}`
                          : ""
                      }
                      theme={focusedMatch.partyTwo.theme}
                      reported={
                        focusedMatch.meta.reported.reporter ===
                        savedUser.user.id
                      }
                      onClick={() => {
                        const match = focusedMatch;
                        history.push(
                          `/letters/${match.id}/${match.partyTwo.id}`,
                        );
                      }}
                    />
                  </div>

                  {homeState.matches.length > 1 && focusedMatch && (
                    <MatchSlider
                      index={matchIndex}
                      setMatchIndex={changeIndex}
                      max={homeState.matches.length - 1}
                      theme={focusedMatch.partyTwo.theme}
                    />
                  )}
                  <div className="h-full w-full"></div>
                </div>
              )}
              {homeState.type === "loading" && (
                <div className="flex h-full w-full flex-col items-center justify-center">
                  <p>Loading...</p>
                </div>
              )}
              {homeState.type === "missingPreferences" && (
                <div className="flex h-full w-full flex-col items-center justify-center">
                  <div className="flex w-full flex-col gap-4">
                    <IonText className="text-center">
                      Please set your match preferences so we can match you with
                      the right person!
                    </IonText>
                    <IonButton color="dark" routerLink="/preferences">
                      Set Preferences
                    </IonButton>
                  </div>
                </div>
              )}
              {homeState.type === "missingAvatar" && (
                <MissingAvatarEmptyState />
              )}
              {homeState.type === "missingLocation" && (
                <div className="flex h-full w-full flex-col items-center justify-center">
                  <div className="flex w-full flex-col gap-4">
                    <IonText className="text-center">
                      Please set your location so we can match you with the
                      people in your city.
                      <br />
                      <b>
                        We only access your location once. You can update your
                        location any time from your profile.
                      </b>
                    </IonText>
                    <IonButton color="dark" routerLink="/change-location">
                      Set Location
                    </IonButton>
                  </div>
                </div>
              )}
              {homeState.type === "noMatches" && (
                <div className="flex h-full w-full flex-col items-center justify-center">
                  <div className="flex h-full w-full flex-col items-center justify-center">
                    <div className="flex w-full flex-col items-center gap-4">
                      <HeartIcon className="h-8 w-8 text-[#f05500]" />
                    </div>
                    <IonText className="text-center">
                      Great things take time! We are carefully finding matches
                      that spark your interest. Hold tight, the magic is about
                      to happen...
                    </IonText>
                  </div>
                </div>
              )}
              {homeState.type === "error" && (
                <div className="flex h-full w-full flex-col items-center justify-center">
                  <div className="flex w-full flex-col items-center gap-4">
                    <IonText>{ERROR_DEFAULT}</IonText>
                    <IonButton
                      color="dark"
                      onClick={(e) => {
                        e.preventDefault();
                        setHomeState({ type: "loading" });
                        load();
                      }}
                    >
                      Retry
                    </IonButton>
                  </div>
                </div>
              )}
              {homeState.type === "applicationStatus" && (
                <div className="flex h-full w-full flex-col items-center justify-center">
                  <div className="flex w-full flex-col items-center gap-4">
                    {homeState.status === ApplicationStatus.InReview && (
                      <>
                        <ClockIcon className="h-8 w-8 animate-pulse text-yellow-500" />
                        <IonText className="text-center">
                          Your profile is currently being reviewed.<br></br> We
                          appreciate your patience. Please check back later for
                          updates on your status.
                        </IonText>
                      </>
                    )}
                    {homeState.status === ApplicationStatus.Rejected && (
                      <>
                        <ExclamationCircleIcon className="h-8 w-8 text-red-500" />
                        <IonText className="text-start text-xl font-semibold">
                          Your profile was rejected.
                        </IonText>
                        {homeState.rejectionReasons &&
                          homeState.rejectionReasons.length > 0 && (
                            <div>
                              <IonText className="text-start">Because:</IonText>
                              <ul className="mt-2 list-inside list-disc">
                                {homeState.rejectionReasons.map((reason) => (
                                  <li key={reason.reason} className="text-sm">
                                    Your {reason.description_EN}
                                  </li>
                                ))}
                              </ul>
                            </div>
                          )}
                      </>
                    )}
                  </div>
                </div>
              )}
            </div>
          </main>
        </div>
      </div>
    </IonPage>
  );
}

function MissingAvatarEmptyState() {
  const userContext = useContext(UserContext);
  if (!userContext?.userState.loggedIn) {
    return <></>;
  }
  const savedUser = userContext.userState.savedUser;
  const [avatar, setAvatar] = useState<AvatarStateType>({
    chosen: false,
    path: "",
  });
  const [inProgress, setInProgress] = useState(false);
  const updateUsersAvatar = (avatarFile: File | Blob) => {
    patchUserAvatar(savedUser, avatarFile)
      .then((result) => {
        if (result.status !== 200) {
          return Promise.reject(result);
        }
        return result.json();
      })
      .then((body) => {
        const user = {
          ...savedUser.user,
          avatar: body.avatar,
        };
        return userContext?.loginHook.saveUser({
          ...savedUser,
          user: user,
        });
      });
  };
  useEffect(() => {
    if (!avatar.chosen || inProgress) {
      return;
    }
    setInProgress(true);
    getAvatarFile(savedUser.user.id, avatar.path)
      .then((avatarFile) => {
        if (!avatarFile) {
          return Promise.reject();
        }
        return compressAvatar(avatarFile);
      })
      .then((compressedAvatar) => {
        return updateUsersAvatar(compressedAvatar);
      })
      .catch((e) => handleError(e, userContext))
      .finally(() => {
        setInProgress(false);
      });
  }, [avatar]);
  return (
    <div className="flex h-full w-full flex-col items-center justify-center gap-8">
      <div className="flex w-full flex-col items-center">
        <div
          className="flex h-36 w-28 flex-col items-center justify-center rounded-md border-2 border-dotted border-gray-500"
          onClick={(e) => {
            e.preventDefault();
            if (inProgress) {
              return;
            }
            Camera.getPhoto({
              source: CameraSource.Photos,
              allowEditing: false,
              saveToGallery: false,
              quality: 90,
              resultType: CameraResultType.DataUrl,
            })
              .then((result) => {
                if (!result.dataUrl) {
                  return Promise.reject(result);
                }
                setAvatar({ chosen: true, path: result.dataUrl });
              })
              .catch((e) => handleError(e, userContext));
          }}
        >
          <UserPlusIcon className="h-8 w-8 text-gray-500" />
        </div>
      </div>
      <div className="flex w-full flex-col gap-4">
        <IonText className="text-start">
          Choose an avatar that clearly shows your face.
        </IonText>
        <IonText className="text-start text-gray-600">
          On BetterDates you can only have one avatar, so pick the best one from
          your gallery.
        </IonText>
      </div>
    </div>
  );
}

function fetchMatches(token: string): Promise<HttpResponse> {
  return GET({
    url: `${JIBI_BASE_URL}/v1/match/`,
    headers: {
      Authorization: token,
    },
  });
}

function fetchApplicationStatus(savedUser: SavedUser): Promise<HttpResponse> {
  return GET({
    url: `${JIBI_BASE_URL}/collections/users_application_statuses_view/records?expand=rejectionReasons`,
    headers: {
      Authorization: savedUser.token,
    },
  });
}

function generateInitialMatchIndex(numberOfMatches: number) {
  if (numberOfMatches % 2 === 0 || numberOfMatches === 1) {
    return 0;
  } else {
    return Math.floor(numberOfMatches / 2);
  }
}

interface AvatarState {
  chosen: boolean;
  path: string;
}

interface AvatarStateChosen extends AvatarState {
  chosen: true;
}

interface AvatarStateEmpty extends AvatarState {
  chosen: false;
}

type AvatarStateType = AvatarStateChosen | AvatarStateEmpty;

type HomeState =
  | HomeStateLoading
  | HomeStateLoaded
  | HomeStateMissingPreferences
  | HomeStateMissingLocation
  | HomeStateMissingAvatar
  | HomeStateNoMatches
  | HomeStateError
  | HomeStateApplicationStatus;

type RejectionReason = {
  reason: string;
  description_EN: string;
};

type ApplicationStatusResponse = {
  items: {
    status: string;
    expand?: {
      rejectionReasons: RejectionReason[];
    };
  }[];
};
type HomeStateApplicationStatus = {
  type: "applicationStatus";
  status: ApplicationStatus;
  rejectionReasons?: RejectionReason[];
};

type HomeStateLoaded = {
  type: "loaded";
  matches: Match[];
};

type HomeStateLoading = {
  type: "loading";
};

type HomeStateMissingPreferences = {
  type: "missingPreferences";
};

type HomeStateMissingLocation = {
  type: "missingLocation";
};

type HomeStateMissingAvatar = {
  type: "missingAvatar";
};

type HomeStateNoMatches = {
  type: "noMatches";
};

type HomeStateError = {
  type: "error";
  error: string;
};
