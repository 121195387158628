import { useContext } from "react";
import MatchInfo from "../../components/MatchInfo";
import { JIBI_BASE_URL } from "../../util/ApiClient";
import { UserContext } from "../../util/BetterDatesApp";
import { calculateAge } from "../../util/date-ext";

export default function PreviewProfile() {
  const userContext = useContext(UserContext);
  if (!userContext?.userState?.loggedIn) {
    return <></>;
  }
  const savedUser = userContext?.userState.savedUser;

  return (
    <div className="flex h-full w-full px-4 pt-2">
      <MatchInfo
        name={savedUser.user.name || ""}
        age={
          savedUser.user.birthday
            ? calculateAge(savedUser.user.birthday)
            : undefined
        }
        avatar={`${JIBI_BASE_URL}/files/_pb_users_auth_/${savedUser.user.id}/${savedUser.user.avatar}`}
        theme={savedUser.user.theme}
        onClick={() => {
          return;
        }}
        state={{
          profilePictureState: {
            state: "innocent",
            transform: () => {
              return;
            },
          },
        }}
      />
    </div>
  );
}
