const dateFormatter = new Intl.DateTimeFormat("en-US", {
  day: "2-digit",
  month: "2-digit",
  year: "numeric",
  hour: "2-digit",
  minute: "2-digit",
  hour12: false,
});

export function calculateAge(birthday: Date): number {
  const currentDate = new Date();
  let age = currentDate.getFullYear() - birthday.getFullYear();
  const monthDiff = currentDate.getMonth() - birthday.getMonth();
  // If the birthday hasn't occurred yet this year, subtract 1 from the age
  if (
    monthDiff < 0 ||
    (monthDiff === 0 && currentDate.getDate() < birthday.getDate())
  ) {
    age--;
  }
  return age;
}

export function formatDateToHumanReadable(date: Date): string {
  return dateFormatter.format(date);
}

export function getDateFormatter(): Intl.DateTimeFormat {
  return dateFormatter;
}
