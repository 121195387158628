import { heicTo, isHeic } from "heic-to";
import { dataUrlToFile } from "./FileExt";
import Compressor from "compressorjs";
import User from "../data/User";
import { isAvatarValid } from "../validation/ProfileValidation";
import { JIBI_BASE_URL } from "./ApiClient";

export function getAvatarUrl(user: User): string {
  return isAvatarValid(user).valid
    ? `${JIBI_BASE_URL}/files/_pb_users_auth_/${user.id}/${user.avatar}`
    : "/assets/avatar_placeholder.svg";
}
export function getAvatarFile(
  userId: string,
  avatarPath: string,
): Promise<File> {
  const fileName = `avatar_${userId}_${new Date().getMilliseconds()}`;
  return dataUrlToFile(avatarPath, fileName);
}

export function compressAvatar(avatarFile: File): Promise<File | Blob> {
  return new Promise((resolve, reject) => {
    const compressPngOrJpeg = () => {
      new Compressor(avatarFile, {
        quality: 0.8,
        convertSize: 3000000,
        maxWidth: 1080,
        convertTypes: ["image/png", "image/jpeg"],
        success(result) {
          resolve(result);
        },
        error(err) {
          reject(err);
        },
      });
    };
    const compressHeic = () => {
      heicTo({ blob: avatarFile, type: "image/jpeg", quality: 0.8 })
        .then((result) => resolve(result))
        .catch((e) => reject(e));
    };
    isHeic(avatarFile)
      .then((isHeicFile) => {
        if (isHeicFile) {
          compressHeic();
        } else {
          compressPngOrJpeg();
        }
      })
      .catch((e) => {
        reject(e);
      });
  });
}
