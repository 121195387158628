import { HttpResponse } from "@capacitor/core";
import { ChevronLeftIcon, SparklesIcon } from "@heroicons/react/20/solid";
import {
  IonAlert,
  IonAvatar,
  IonButton,
  IonChip,
  IonContent,
  IonImg,
  IonLabel,
  IonPage,
  IonRippleEffect,
  IonText,
} from "@ionic/react";
import { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import Letter from "../../data/Letter";
import { Match } from "../../data/Match";
import { MatchResponse } from "../../data/MatchesResponse";
import { InMemoryCache } from "../../hooks/CacheHook";
import { GET, JIBI_BASE_URL, POST } from "../../util/ApiClient";
import { UserContext } from "../../util/BetterDatesApp";
import { requestNotificationPermission } from "../../util/NotificationExt";
import { handleError } from "../../util/error";
import ScrollableContent from "../../components/ScrollableContent";

export default function WriteLetter() {
  const params = useParams<WriteLetterProps>();
  const history = useHistory();
  const userContext = useContext(UserContext);
  const [state, setState] = useState<WriteLetterStateType>({ loading: true });
  const [letter, setLetter] = useState("");
  const [isHintPopupOpen, setIsHintPopupOpen] = useState(false);
  if (!userContext?.userState.loggedIn) {
    return <></>;
  }
  const [inProgress, setInProgress] = useState(false);

  const savedUser = userContext.userState.savedUser;

  useEffect(() => {
    const cachedMatch = getCachedMatch(userContext.cache, params.matchId);
    if (cachedMatch) {
      setState({ loading: false, match: cachedMatch });
    } else {
      getMatchInfo(params.matchId, savedUser.token)
        .then((result) => {
          if (result.status !== 200) {
            return Promise.reject(result);
          }
          setState({
            loading: false,
            match: (result.data as MatchResponse).match,
          });
        })
        .catch((e) => handleError(e, userContext));
    }
  }, []);

  return (
    <IonPage className="flex items-center justify-center bg-gray-50">
      <div className="h-full w-full max-w-md">
        <div className="safe-scroller flex h-full w-full flex-col">
          <header className="flex h-14 flex-row items-center justify-between px-3">
            <IonChip
              onClick={(e) => {
                e.preventDefault();
                history.goBack();
              }}
            >
              <ChevronLeftIcon className="me-1 h-5 w-5 text-gray-500" />
              <IonLabel>Back</IonLabel>
            </IonChip>
            <IonButton
              className={"h-9 w-20"}
              shape={"round"}
              color={"dark"}
              size={"small"}
              disabled={letter.length < 1 || inProgress}
              onClick={(e) => {
                e.preventDefault();
                setInProgress(true);
                sendLetter(params.matchId, letter, savedUser.token)
                  .then((result) => {
                    if (result.status !== 201) {
                      return Promise.reject(result);
                    }
                    const newLetter = result.data.letter as Letter;
                    const cachedLetters =
                      userContext.cache.letters.get(params.matchId) || [];
                    const updatedLetters = [newLetter, ...cachedLetters];
                    userContext.cacheHook.cacheLetters(
                      params.matchId,
                      updatedLetters,
                    );

                    requestNotificationPermission(userContext);

                    history.replace(
                      `/letter/${params.matchId}/${newLetter.id}`,
                    );
                  })
                  .catch((e) => handleError(e, userContext))
                  .finally(() => {
                    setInProgress(false);
                  });
              }}
            >
              Send
            </IonButton>
          </header>
          <main className="flex h-full w-full flex-col">
            {state.loading ? (
              <div className="flex h-full w-full items-center justify-center">
                <IonText>Loading...</IonText>
              </div>
            ) : (
              <div className="flex h-full w-full flex-col">
                <div className="ion-padding flex flex-row items-center justify-between">
                  <div className="flex flex-row items-center gap-3">
                    <IonAvatar className="h-12 w-12">
                      <IonImg
                        src={`${JIBI_BASE_URL}/files/_pb_users_auth_/${state.match.partyTwo.id}/${state.match.partyTwo.avatar}`}
                      />
                    </IonAvatar>
                    <div className="flex flex-col">
                      <IonText className=" text-sm text-gray-500">
                        You&apos;re sending a letter to
                      </IonText>
                      <div className="flex flex-row items-baseline gap-2">
                        <IonText className="font-mono text-xl">
                          {state.match.partyTwo.name}
                        </IonText>
                        <IonText className="font-mono text-sm text-gray-600">
                          {state.match.partyTwo.age}
                        </IonText>
                      </div>
                    </div>
                  </div>
                  {state.match.partyTwo.hint.length > 0 && (
                    <div
                      className="ion-activatable relative flex h-10 w-10 items-center justify-center overflow-hidden rounded-full"
                      onClick={(e) => {
                        e.preventDefault();
                        setIsHintPopupOpen(true);
                      }}
                    >
                      <SparklesIcon className="h-8 w-8 text-violet-500" />
                      <IonRippleEffect />
                    </div>
                  )}
                </div>

                <IonContent scrollY={false}>
                  <ScrollableContent>
                    <textarea
                      dir="auto"
                      className="h-full w-full resize-none border-none bg-transparent px-5 py-2 text-lg focus:outline-none"
                      placeholder="Write here..."
                      value={letter}
                      spellCheck={true}
                      maxLength={2048}
                      onChange={(e) => {
                        const newInput = e.target.value as string;
                        setLetter(newInput);
                      }}
                    />
                  </ScrollableContent>
                  
                </IonContent>

                <IonAlert
                  isOpen={isHintPopupOpen}
                  header="AI Hint(Experimental)"
                  message={state.match.partyTwo.hint}
                  buttons={["Close"]}
                  onDidDismiss={() => setIsHintPopupOpen(false)}
                />
              </div>
            )}
          </main>
        </div>
      </div>
    </IonPage>
  );
}

function getCachedMatch(
  cache: InMemoryCache,
  matchId: string,
): Match | undefined {
  const cachedMatches = cache.matches;
  return (
    cachedMatches?.find((element) => element.partyTwo.id === matchId) ||
    undefined
  );
}

function getMatchInfo(matchId: string, token: string): Promise<HttpResponse> {
  return GET({
    url: `${JIBI_BASE_URL}/v1/match/${matchId}/`,
    headers: {
      Authorization: token,
    },
  });
}

function sendLetter(
  matchId: string,
  letter: string,
  token: string,
): Promise<HttpResponse> {
  return POST({
    url: `${JIBI_BASE_URL}/v1/letter/${matchId}`,
    headers: {
      Authorization: token,
    },
    body: {
      letter: letter,
    },
  });
}

interface WriteLetterState {
  loading: boolean;
}

interface WriteLetterStateLoading extends WriteLetterState {
  loading: true;
}

interface WriteLetterStateLoaded extends WriteLetterState {
  loading: false;
  match: Match;
}

type WriteLetterStateType = WriteLetterStateLoaded | WriteLetterStateLoading;

export type WriteLetterProps = {
  matchId: string;
  partyTwoId: string;
};
